<template>
  <template v-if="type === 'separator'">
    <hr v-if="showSeparatorTopLine" class="my-2 w-full" />
    <div
      class="text-md ml-2 mt-2 flex items-center justify-center md:mb-2 md:mt-0"
    >
      <p v-if="!mini" class="w-full text-left">{{ name }}</p>
    </div>
  </template>
  <div
    v-else
    class="flex items-center justify-center"
    :class="{
      'pl-2': !mini,
      'rounded-lg bg-gradient-to-r from-primary to-accent py-0.5 text-black':
        fullPath.startsWith(href),
    }"
  >
    <div
      v-if="type === 'link'"
      class="tooltip-hover tooltip-right tooltip"
      :data-tip="name"
    >
      <router-link :to="href">
        <icon-component
          :name="icon"
          type="solid"
          :classes="mini ? 'h-9 w-6' : 'h-9 w-5'"
        />
      </router-link>
    </div>
    <div
      v-if="mini && type === 'dropdown'"
      class="flex w-full flex-col justify-start text-left"
    >
      <Disclosure v-slot="{ open }">
        <DisclosureButton class="flex w-full justify-between">
          <icon-component :name="icon" type="solid" classes="h-5 w-5" />
          <icon-component
            v-if="open"
            name="ChevronUp"
            type="solid"
            classes="h-5 w-5"
          />
          <icon-component
            v-else
            name="ChevronDown"
            type="solid"
            classes="h-5 w-5"
          />
        </DisclosureButton>
        <DisclosurePanel class="pl-3 text-gray-300">
          <div v-if="type === 'separator'" class="text-left">
            <p>{{ name }}</p>
          </div>
          <div
            v-else
            v-for="c in children"
            class="flex items-center justify-center text-center"
          >
            <router-link :to="c.href">
              <icon-component :name="c.icon" type="solid" classes="h-6 w-6" />
            </router-link>
          </div>
        </DisclosurePanel>
      </Disclosure>
    </div>
    <div v-if="!mini" class="flex w-full items-center justify-between">
      <router-link
        v-if="type === 'link'"
        :to="href"
        class="text-md w-full font-semibold"
      >
        <p class="pl-2 text-left">{{ name }}</p>
      </router-link>
      <div
        v-if="type === 'dropdown'"
        class="flex w-full flex-col justify-start text-left"
      >
        <Disclosure v-slot="{ open }">
          <DisclosureButton class="flex w-full justify-between">
            <icon-component :name="icon" type="solid" classes="h-9 w-9" />
            <p>{{ name }}</p>
            <icon-component
              v-if="open"
              name="ChevronUp"
              type="solid"
              classes="h-9 w-9"
            />
            <icon-component
              v-else
              name="ChevronDown"
              type="solid"
              classes="h-9 w-9"
            />
          </DisclosureButton>
          <DisclosurePanel class="pl-3 text-gray-300">
            <div v-if="type === 'separator'" class="text-left">
              <p>{{ name }}</p>
            </div>
            <div
              v-else
              v-for="c in children"
              class="flex items-center justify-center text-center"
            >
              <router-link :to="c.href">
                <icon-component :name="c.icon" type="solid" classes="h-8 w-8" />
              </router-link>
              <router-link :to="c.href" class="w-full">
                <p>{{ c.name }}</p>
              </router-link>
            </div>
          </DisclosurePanel>
        </Disclosure>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, toRefs } from "vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { useRoute } from "vue-router";

const route = useRoute();

// Props
const props = defineProps([
  "mini",
  "name",
  "href",
  "icon",
  "type",
  "children",
  "showSeparatorTopLine",
]);

// Variables
const {
  activeRoute,
  mini,
  name,
  href,
  icon,
  type,
  children,
  showSeparatorTopLine,
} = toRefs(props);
const { fullPath } = toRefs(route);
</script>
