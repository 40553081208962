// Solid Icons
import {
  AdjustmentsVerticalIcon as solidAdjustmentsVerticalIcon,
  ArchiveBoxIcon as solidArchiveBoxIcon,
  ArrowTopRightOnSquareIcon as solidArrowTopRightOnSquareIcon,
  ArrowDownOnSquareIcon as solidArrowDownOnSquareIcon,
  ArrowLeftOnRectangleIcon as solidArrowLeftOnRectangleIcon,
  ArrowLeftIcon as solidArrowLeftIcon,
  ArrowPathIcon as solidArrowPathIcon,
  ArrowRightIcon as solidArrowRightIcon,
  ArrowRightOnRectangleIcon as solidArrowRightOnRectangleIcon,
  ArrowLongUpIcon as solidArrowLongUpIcon,
  ArrowLongDownIcon as solidArrowLongDownIcon,
  BellIcon as solidBellIcon,
  BoltSlashIcon as solidBoltSlashIcon,
  BuildingStorefrontIcon as solidBuildingStorefrontIcon,
  StopIcon as solidStopIcon,
  BriefcaseIcon as solidBriefcaseIcon,
  CreditCardIcon as solidCreditCardIcon,
  CheckIcon as solidCheckIcon,
  CheckCircleIcon as solidCheckCircleIcon,
  ChartPieIcon as solidChartPieIcon,
  ChevronDownIcon as solidChevronDownIcon,
  ChevronUpIcon as solidChevronUpIcon,
  ChevronLeftIcon as solidChevronLeftIcon,
  ClockIcon as solidClockIcon,
  ComputerDesktopIcon as solidComputerDesktopIcon,
  Cog8ToothIcon as solidCog8ToothIcon,
  DevicePhoneMobileIcon as solidDevicePhoneMobileIcon,
  EyeIcon as solidEyeIcon,
  FunnelIcon as solidFunnelIcon,
  LockClosedIcon as solidLockClosedIcon,
  LockOpenIcon as solidLockOpenIcon,
  RectangleStackIcon as solidRectangleStackIcon,
  CubeIcon as solidCubeIcon,
  ChartBarIcon as solidChartBarIcon,
  ExclamationTriangleIcon as solidExclamationTriangleIcon,
  EllipsisHorizontalIcon as solidEllipsisHorizontalIcon,
  EllipsisVerticalIcon as solidEllipsisVerticalIcon,
  InformationCircleIcon as solidInformationCircleIcon,
  GiftIcon as solidGiftIcon,
  GiftTopIcon as solidGiftTopIcon,
  Bars3Icon as solidBars3Icon,
  CurrencyDollarIcon as solidCurrencyDollarIcon,
  LinkIcon as solidLinkIcon,
  MinusIcon as solidMinusIcon,
  NoSymbolIcon as solidNoSymbolIcon,
  PencilIcon as solidPencilIcon,
  PencilSquareIcon as solidPencilSquareIcon,
  PlusIcon as solidPlusIcon,
  PowerIcon as solidPowerIcon,
  PrinterIcon as solidPrinterIcon,
  QuestionMarkCircleIcon as solidQuestionMarkCircleIcon,
  ReceiptPercentIcon as solidReceiptPercentIcon,
  MagnifyingGlassIcon as solidMagnifyingGlassIcon,
  MapPinIcon as solidMapPinIcon,
  ShieldCheckIcon as solidShieldCheckIcon,
  ShoppingCartIcon as solidShoppingCartIcon,
  ShoppingBagIcon as solidShoppingBagIcon,
  StarIcon as solidStarIcon,
  TableCellsIcon as solidTableCellsIcon,
  TrashIcon as solidTrashIcon,
  TicketIcon as solidTicketIcon,
  TruckIcon as solidTruckIcon,
  UserCircleIcon as solidUserCircleIcon,
  UserIcon as solidUserIcon,
  UserGroupIcon as solidUserGroupIcon,
  UsersIcon as solidUsersIcon,
  WrenchScrewdriverIcon as solidWrenchScrewdriverIcon,
  XMarkIcon as solidXMarkIcon,
  XCircleIcon as solidXCircleIcon,
} from "@heroicons/vue/24/solid";
// Outline Icons
import {
  AdjustmentsVerticalIcon as outlineAdjustmentsVerticalIcon,
  ArchiveBoxIcon as outlineArchiveBoxIcon,
  ArrowTopRightOnSquareIcon as outlineArrowTopRightOnSquareIcon,
  ArrowDownOnSquareIcon as outlineArrowDownOnSquareIcon,
  ArrowLeftOnRectangleIcon as outlineArrowLeftOnRectangleIcon,
  ArrowLeftIcon as outlineArrowLeftIcon,
  ArrowPathIcon as outlineArrowPathIcon,
  ArrowRightIcon as outlineArrowRightIcon,
  ArrowRightOnRectangleIcon as outlineArrowRightOnRectangleIcon,
  ArrowLongUpIcon as outlineArrowLongUpIcon,
  ArrowLongDownIcon as outlineArrowLongDownIcon,
  BellIcon as outlineBellIcon,
  BoltSlashIcon as outlineBoltSlashIcon,
  BriefcaseIcon as outlineBriefcaseIcon,
  BuildingStorefrontIcon as outlineBuildingStorefrontIcon,
  StopIcon as outlineStopIcon,
  CreditCardIcon as outlineCreditCardIcon,
  CheckIcon as outlineCheckIcon,
  CheckCircleIcon as outlineCheckCircleIcon,
  ChartPieIcon as outlineChartPieIcon,
  ChevronDownIcon as outlineChevronDownIcon,
  ChevronUpIcon as outlineChevronUpIcon,
  ChevronLeftIcon as outlineChevronLeftIcon,
  ChartBarIcon as outlineChartBarIcon,
  ClockIcon as outlineClockIcon,
  ComputerDesktopIcon as outlineComputerDesktopIcon,
  Cog8ToothIcon as outlineCog8ToothIcon,
  DevicePhoneMobileIcon as outlineDevicePhoneMobileIcon,
  ExclamationTriangleIcon as outlineExclamationTriangleIcon,
  EyeIcon as outlineEyeIcon,
  FunnelIcon as outlineFunnelIcon,
  GiftTopIcon as outlineGiftTopIcon,
  LockClosedIcon as outlineLockClosedIcon,
  LockOpenIcon as outlineLockOpenIcon,
  RectangleStackIcon as outlineRectangleStackIcon,
  CubeIcon as outlineCubeIcon,
  EllipsisHorizontalIcon as outlineEllipsisHorizontalIcon,
  EllipsisVerticalIcon as outlineEllipsisVerticalIcon,
  InformationCircleIcon as outlineInformationCircleIcon,
  GiftIcon as outlineGiftIcon,
  Bars3Icon as outlineBars3Icon,
  CurrencyDollarIcon as outlineCurrencyDollarIcon,
  LinkIcon as outlineLinkIcon,
  MinusIcon as outlineMinusIcon,
  NoSymbolIcon as outlineNoSymbolIcon,
  PencilIcon as outlinePencilIcon,
  PencilSquareIcon as outlinePencilSquareIcon,
  PlusIcon as outlinePlusIcon,
  PowerIcon as outlinePowerIcon,
  PrinterIcon as outlinePrinterIcon,
  QuestionMarkCircleIcon as outlineQuestionMarkCircleIcon,
  ReceiptPercentIcon as outlineReceiptPercentIcon,
  MagnifyingGlassIcon as outlineMagnifyingGlassIcon,
  MapPinIcon as outlineMapPinIcon,
  ShieldCheckIcon as outlineShieldCheckIcon,
  ShoppingCartIcon as outlineShoppingCartIcon,
  ShoppingBagIcon as outlineShoppingBagIcon,
  StarIcon as outlineStarIcon,
  TrashIcon as outlineTrashIcon,
  TableCellsIcon as outlineableCellsIcon,
  TicketIcon as outlineTicketIcon,
  TruckIcon as outlineTruckIcon,
  UserCircleIcon as outlineUserCircleIcon,
  UserIcon as outlineUserIcon,
  UserGroupIcon as outlineUserGroupIcon,
  UsersIcon as outlineUsersIcon,
  WrenchScrewdriverIcon as outlineWrenchScrewdriverIcon,
  XMarkIcon as outlineXMarkIcon,
  XCircleIcon as outlineXCircleIcon,
} from "@heroicons/vue/24/outline";

export const iconsSet = Object.assign(
  {},
  {
    // Solid Icons
    solidAdjustmentsVerticalIcon,
    solidArchiveBoxIcon,
    solidArrowTopRightOnSquareIcon,
    solidArrowDownOnSquareIcon,
    solidArrowLeftOnRectangleIcon,
    solidArrowLeftIcon,
    solidArrowPathIcon,
    solidArrowRightIcon,
    solidArrowRightOnRectangleIcon,
    solidArrowLongUpIcon,
    solidArrowLongDownIcon,
    solidBellIcon,
    solidBoltSlashIcon,
    solidBriefcaseIcon,
    solidBuildingStorefrontIcon,
    solidStopIcon,
    solidCreditCardIcon,
    solidChartPieIcon,
    solidChartBarIcon,
    solidCheckIcon,
    solidCheckCircleIcon,
    solidChevronDownIcon,
    solidChevronUpIcon,
    solidChevronLeftIcon,
    solidClockIcon,
    solidComputerDesktopIcon,
    solidCog8ToothIcon,
    solidDevicePhoneMobileIcon,
    solidExclamationTriangleIcon,
    solidEyeIcon,
    solidFunnelIcon,
    solidLockClosedIcon,
    solidLockOpenIcon,
    solidRectangleStackIcon,
    solidCubeIcon,
    solidEllipsisHorizontalIcon,
    solidEllipsisVerticalIcon,
    solidInformationCircleIcon,
    solidGiftIcon,
    solidGiftTopIcon,
    solidBars3Icon,
    solidCurrencyDollarIcon,
    solidLinkIcon,
    solidMinusIcon,
    solidNoSymbolIcon,
    solidPencilIcon,
    solidPencilSquareIcon,
    solidPlusIcon,
    solidPowerIcon,
    solidPrinterIcon,
    solidQuestionMarkCircleIcon,
    solidReceiptPercentIcon,
    solidMagnifyingGlassIcon,
    solidMapPinIcon,
    solidShieldCheckIcon,
    solidShoppingCartIcon,
    solidShoppingBagIcon,
    solidStarIcon,
    solidTrashIcon,
    solidTableCellsIcon,
    solidTicketIcon,
    solidTruckIcon,
    solidUserCircleIcon,
    solidUserIcon,
    solidUserGroupIcon,
    solidUsersIcon,
    solidWrenchScrewdriverIcon,
    solidXMarkIcon,
    solidXCircleIcon,
    // Outline Icons
    outlineAdjustmentsVerticalIcon,
    outlineArchiveBoxIcon,
    outlineArrowTopRightOnSquareIcon,
    outlineArrowDownOnSquareIcon,
    outlineArrowLeftOnRectangleIcon,
    outlineArrowLeftIcon,
    outlineArrowPathIcon,
    outlineArrowRightIcon,
    outlineArrowRightOnRectangleIcon,
    outlineArrowLongUpIcon,
    outlineArrowLongDownIcon,
    outlineBellIcon,
    outlineBoltSlashIcon,
    outlineBriefcaseIcon,
    outlineBuildingStorefrontIcon,
    outlineStopIcon,
    outlineCreditCardIcon,
    outlineChartBarIcon,
    outlineChartPieIcon,
    outlineCheckIcon,
    outlineCheckCircleIcon,
    outlineChevronDownIcon,
    outlineChevronUpIcon,
    outlineChevronLeftIcon,
    outlineClockIcon,
    outlineComputerDesktopIcon,
    outlineCog8ToothIcon,
    outlineDevicePhoneMobileIcon,
    outlineExclamationTriangleIcon,
    outlineEyeIcon,
    outlineFunnelIcon,
    outlineGiftTopIcon,
    outlineLockClosedIcon,
    outlineLockOpenIcon,
    outlineRectangleStackIcon,
    outlineCubeIcon,
    outlineEllipsisHorizontalIcon,
    outlineEllipsisVerticalIcon,
    outlineInformationCircleIcon,
    outlineGiftIcon,
    outlineBars3Icon,
    outlineCurrencyDollarIcon,
    outlineLinkIcon,
    outlineMinusIcon,
    outlineNoSymbolIcon,
    outlinePencilIcon,
    outlineQuestionMarkCircleIcon,
    outlinePencilSquareIcon,
    outlinePlusIcon,
    outlinePowerIcon,
    outlinePrinterIcon,
    outlineReceiptPercentIcon,
    outlineMagnifyingGlassIcon,
    outlineMapPinIcon,
    outlineShieldCheckIcon,
    outlineShoppingCartIcon,
    outlineShoppingBagIcon,
    outlineStarIcon,
    outlineTrashIcon,
    outlineableCellsIcon,
    outlineTicketIcon,
    outlineTruckIcon,
    outlineUserCircleIcon,
    outlineUserIcon,
    outlineUserGroupIcon,
    outlineUsersIcon,
    outlineWrenchScrewdriverIcon,
    outlineXMarkIcon,
    outlineXCircleIcon,
  }
);
